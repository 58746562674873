import {useEffect} from 'react'
import isScrapping from 'App/helpers/misc/isScrapping'

export default function InsidersIntegration(props) {
  const {integrations: config} = props

  useEffect(() => {
    if (!isScrapping && config && config.integrations && config.integrations.insidersUrl) {
      const script = document.createElement('script')
      const url = config.integrations.insidersUrl
      script.src = url
      script.async = true

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [config])

  return null
}
