import React from 'react'
import SetLocale from './SetLocale'

export default function Locale(props) {
  const {website} = props

  if (!website) return null
  if (!website.country) return null
  return <SetLocale locale={website.country.locale} />
}
