import useInitialData from './useInitialData'
import useQuery from 'apollo-hooks/lib/useApolloQuery'

export default function(options) {
  const initialData = useInitialData()

  const result = useQuery({
    ...options,
    omit: !!initialData || !!options.omit
  })

  return initialData ? initialData : result
}
