import React from 'react'
import MessengerCustomerChat from 'react-messenger-customer-chat'
import useEffect from 'App/hooks/useEffect'
import useCookiesAccepted from 'App/hooks/useCookiesAccepted'

export default function FacebookChat(props) {
  const {integrations: config} = props
  const cookies = useCookiesAccepted()

  const getFacebookPageId = () => {
    if (!config) return null
    if (!config.integrations) return null
    if (!config.integrations.facebookChatPageId) return null
    return config.integrations.facebookChatPageId
  }

  useEffect(() => {
    return () => {
      try {
        global.FB.CustomerChat.hide()
      } catch (error) {}
    }
  })

  if (!getFacebookPageId() || !cookies) return null

  return (
    <MessengerCustomerChat
      appId="412212256206068"
      language="es_ES"
      version="3.2"
      pageId={getFacebookPageId()}
    />
  )
}
