import {createClient} from '@orion-js/graphql-client'
import {InMemoryCache} from 'apollo-cache-inmemory'
import getDeviceId from 'App/helpers/auth/getDeviceId'
import getDomain from 'App/helpers/websites/getDomain'
import getTimezone from './getTimezone'
import getFingerprint from 'App/helpers/auth/getFingerprint'
import refreshJWT from '../refreshJWT'
import {WRAPPED_WEBSITE_ID_KEY} from 'App/components/WrappedWebsite/constants'
import TwoFactorPromptProvider from 'App/Root/TwoFactorPromptProvider'
import {getSavedValue} from 'App/hooks/useSessionStorageState'
import getPosHeaders from './getPosHeaders'

export default ({endpointURL, useSubscriptions}) => {
  // use unique session key per project to avoid
  // loging in and out every time you change it
  // in localhost
  const cache = new InMemoryCache({
    dataIdFromObject: function(object) {
      if (object._id) return object._id
      if (object.__typename === 'Cart') return 'cart'
    }
  })

  return createClient({
    endpointURL,
    useSubscriptions,
    cache,
    batch: false,
    resolvers: {},
    promptTwoFactorCode: TwoFactorPromptProvider.promptTwoFactor,
    getHeaders(body) {
      const timezone = getTimezone()
      const storedWebsiteId = getSavedValue(WRAPPED_WEBSITE_ID_KEY)

      return {
        'X-ORION-DEVICEID': getDeviceId(),
        'X-ORION-FP': getFingerprint(),
        'X-ORION-DOMAIN': getDomain() || '',
        'X-ORION-REFERRER': document.referrer,
        'X-ORION-TIMEZONE': timezone,
        'X-ORION-PATHNAME': window.location.pathname,
        ...(storedWebsiteId ? {'X-ORION-WRAPPED-WEBSITE': storedWebsiteId} : {}),
        ...getPosHeaders()
      }
    },
    saveSession(session) {},
    getSession(session) {
      return {}
    },
    getJWT: () => localStorage.getItem('justo_jwt_token') || null,
    refreshJWT,
    refreshJWTEvery: 1000 * 60 * 5
  })
}
