import getEnv from './getEnv'
import getCustom from './getCustom'

const urls = {
  local: `http://localhost:3000`,
  dev: 'https://api.bejusto.com',
  prod: 'https://api.getjusto.com'
}

const env = getEnv()

export default getCustom('api') || urls[env]
