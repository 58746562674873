import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import {urls} from '../baseURL'
import getEnv from '../getEnv'

export const neutralLngs = ['es']
export const supportedLngs = ['es', 'es-CL', 'es-CO', 'es-CR', 'es-EC', 'es-MX', 'es-PE']

i18n
  .use(LanguageDetector)
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    debug: false,
    backend: {
      loadPath: function(lng, ns) {
        const env = getEnv()
        return `${env === 'prod' ? urls[env] : ''}/locales/${lng}/${ns}.json`
      }
    },
    fallbackLng: code => {
      if (!code) return 'es'
      const [fallback] = code.split('-')
      if (neutralLngs.includes(fallback)) return fallback
      return 'es'
    },
    supportedLngs,
    ns: ['website', 'websiteAdmin', 'pos', 'generic'],
    defaultNS: 'website',
    interpolation: {
      escapeValue: false
    },
    detection: {
      caches: []
    },
    react: {
      bindI18n: 'languageChanged'
    }
  })

export default i18n
