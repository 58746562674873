import React from 'react'
import useForceUpdate from 'App/hooks/useForceUpdate'

const Context = React.createContext()

function GlobalState(props) {
  const forceUpdate = useForceUpdate()
  return <Context.Provider value={forceUpdate}>{props.children}</Context.Provider>
}

export {GlobalState, Context}
