export default {
  abbreviations: {
    thousand: 'k',
    million: 'mm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: function(number) {
    var b = number % 10
    switch (b) {
      case 0:
        return 'mo'
      case 1:
        return 'er'
      case 2:
        return 'do'
      case 3:
        return 'er'
      case 7:
        return 'mo'
      case 8:
        return 'vo'
      case 9:
        return 'no'
      default:
        return 'to'
    }
  }
}
