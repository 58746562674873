import React, {lazy, Suspense} from 'react'
import authRouteRegex from './Auth/routeRegex'
import isEnviromentDomain from 'App/helpers/misc/domain/isEnviromentDomain'
import getSubdomain from 'App/helpers/misc/domain/getSubdomain'
import isInSubdomain from 'App/helpers/misc/domain/isInSubdomain'
import isInSalesPoint from 'App/helpers/misc/isInSalesPoint'
import Layout from './Layout'
import Loading from './Layout/Loading'
import useRouter from 'App/hooks/useRouter'
import getEnv from 'App/Root/getEnv'
import NotFound from 'App/Pages/WebsiteAdmin/NotFound'

const Auth = lazy(() => import('./Auth'))
const WebsiteAdmin = lazy(() => import('./WebsiteAdmin'))
const Home2 = lazy(() => import('./Home2')) // ya no se usa
const SupportCenter = lazy(() => import('./SupportCenter'))
const Sales = lazy(() => import('./Sales'))
const Data = lazy(() => import('./Data')) // migrado
const Sign = lazy(() => import('./Sign'))
const OnBoarding = lazy(() => import('./OnBoarding'))
const Content = lazy(() => import('./Content'))
const Account = lazy(() => import('./Account'))
const Pos = lazy(() => import('./Pos'))
const Tracking = lazy(() => import('./Tracking'))
const CoinsAdmin = lazy(() => import('./CoinsAdmin'))
const Accelerator = lazy(() => import('./Accelerator'))
const Logistics = lazy(() => import('./Logistics'))
const People = lazy(() => import('./People'))
const Errors = lazy(() => import('./Errors'))
const RedirectInboundLanding = lazy(() => import('src/App/Pages/Home2/RedirectInboundLanding'))

export default function Pages(props) {
  const {location} = useRouter()

  const renderContent = () => {
    const {pathname} = location

    if (authRouteRegex.test(pathname)) {
      return <Auth />
    }

    if (isInSubdomain('pos')) {
      return <Pos />
    }

    if (isEnviromentDomain()) {
      if (isInSubdomain('unete')) {
        return <RedirectInboundLanding />
      }

      if (isInSubdomain('web', 'www', 'jobs', '')) {
        return <Home2 />
      }

      const Component = {
        pos: Pos,
        admin: WebsiteAdmin,
        websiteadminframe: WebsiteAdmin,
        support: SupportCenter,
        data: Data,
        sales: Sales,
        account: Account,
        onboarding: OnBoarding,
        content: Content,
        sign: Sign,
        tracking: Tracking,
        coinsadmin: CoinsAdmin,
        accelerator: Accelerator,
        people: People,
        logistics: Logistics
      }[getSubdomain()]
      if (Component) return <Component />
    }

    if (isInSalesPoint() && getEnv() === 'prod') {
      window.location.href = 'https://pos.getjusto.com'
      return null
    }

    if (pathname.startsWith('/admin')) {
      return <WebsiteAdmin />
    }

    if (pathname.startsWith('/errors')) {
      return <Errors />
    }

    return <NotFound />
  }

  return (
    <Suspense fallback={<Loading />}>
      <Layout>{renderContent()}</Layout>
    </Suspense>
  )
}
