import logger from 'App/helpers/logger'

export default function getLocalStorageState(key, defaultValue) {
  try {
    const savedValue = localStorage.getItem(key)
    if (savedValue === null) {
      return defaultValue
    } else {
      try {
        return JSON.parse(savedValue)
      } catch (error) {
        logger.error('[getLocalStorageState] : Error parsing savedValue to JSON', {
          key,
          savedValue,
          error
        })
        return defaultValue
      }
    }
  } catch (error) {
    logger.error('[getLocalStorageState] : Error getting item from localStorage', {
      key,
      error
    })
    return defaultValue
  }
}
