import useForceLogin from 'App/hooks/useForceLogin'
import useRoles from 'App/hooks/useRoles'
import useRouter from 'App/hooks/useRouter'
import authRouteRegex from 'App/Pages/Auth/routeRegex'

export default function WithAuth(props) {
  const {website} = props
  const forceLogin = useForceLogin
  const roles = useRoles()
  const {location, history} = useRouter()
  const {pathname} = location

  if (authRouteRegex.test(pathname) || pathname.startsWith('/errors')) {
    return null
  }

  if (website && website.authOnlyJustoUsers) {
    forceLogin()
    if (!roles.length) history.push('/errors/403')
  }

  return null
}
