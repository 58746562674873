import useEffect from 'App/hooks/useEffect'
import TagManager from 'react-gtm-module'
import useCookiesAccepted from 'App/hooks/useCookiesAccepted'

export default function GoogleTagManager(props) {
  const {integrations: config} = props
  const cookies = useCookiesAccepted()

  const getCode = () => {
    if (!config) return
    if (!config.integrations) return
    if (!config.integrations.tagManagerId) return

    return config.integrations.tagManagerId
  }

  useEffect(() => {
    const gtmId = getCode()
    if (!gtmId || !cookies) return
    TagManager.initialize({gtmId})
  }, [cookies])

  return null
}
