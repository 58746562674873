import mixpanel from 'mixpanel-browser'
import getEnv from 'App/Root/getEnv'
import getLocalStorageState from 'App/hooks/getLocalStorageState'
import logger from 'App/helpers/logger'

const getCookies = () => {
  const prompCookies = getLocalStorageState('prompCookies')
  const cookies = prompCookies ? JSON.parse(prompCookies).userClickAccepted : false
  return cookies
}

export const setMixpanelUserId = userId => {
  if ((getEnv() !== 'prod' && getEnv() !== 'dev') || !getCookies()) return

  try {
    mixpanel.identify(userId)
  } catch {
    logger.exception('Mixpanel not loaded')
  }
}

export const sendMixpanelUserInfo = userInfo => {
  if ((getEnv() !== 'prod' && getEnv() !== 'dev') || !getCookies()) return

  try {
    mixpanel.people.set(userInfo)
  } catch {
    logger.exception('Mixpanel not loaded')
  }
}

export const sendMixpanelData = (eventType, eventProperties = {}) => {
  if ((getEnv() !== 'prod' && getEnv() !== 'dev') || !getCookies()) return

  eventProperties = {
    ...eventProperties
  }
  try {
    mixpanel.track(eventType, eventProperties)
  } catch {
    logger.exception('Mixpanel not loaded')
  }
}

export const EVENTS = Object.freeze({
  clicked: {
    productChangeAmount: 'clicked-productChangeAmount',
    addressComponentOrder: 'clicked-addressComponentOrder',
    checkoutLogin: 'clicked-checkoutLogin',
    upselling: 'clicked-upselling',
    login: 'clicked-login',
    cartRemoveProduct: 'clicked-cartRemoveProduct',
    cartContinueToCheckout: 'clicked-cartContinueToCheckout',
    cart: 'clicked-cart',
    search: 'clicked-search',
    product: 'clicked-product',
    productAddToCart: 'clicked-productAddToCart',
    help: 'clicked-help',
    cancelOrder: 'clicked-cancel-order',
    changePreferredStore: 'clicked-change-preferred-store',
    omitLogin: 'clicked-omit-login',
    editProduct: 'clicked-edit-product',
    saveEditProduct: 'clicked-save-edit-product',
    giftCheckout: 'clicked-gift-checkout'
  },
  product: {
    setQuantityTo0: 'update-quantity-0-product'
  },
  pageView: {
    checkout: 'pageView-checkout',
    order: 'pageView-order',
    home: 'pageView-home',
    tracking: 'pageView-tracking',
    publication: 'pageView-publication'
  },
  coupon: {
    apply: 'coupon-apply',
    failed: 'coupon-failed'
  },
  address: {
    failed: 'address-failed',
    accept: 'address-accept'
  },
  pay: {
    passValidations: 'pay-passValidations',
    failed: 'pay-failed',
    successful: 'pay-successful',
    successfulTest: 'pay-successful-test'
  },
  websiteAdmin: {
    pageView: {
      dashboard: 'websiteAdmin-pageView-dashboard',
      orders: 'websiteAdmin-pageView-orders',
      deliveries: 'websiteAdmin-pageView-deliveries',
      reviews: 'websiteAdmin-pageView-reviews',
      inStoreCoupons: 'websiteAdmin-pageView-inStoreCoupons',
      coupons: 'websiteAdmin-pageView-coupons',
      clients: 'websiteAdmin-pageView-clients',
      visitsLive: 'websiteAdmin-pageView-visitsLive',
      qrGenerator: 'websiteAdmin-pageView-qrGenerator',
      segments: 'websiteAdmin-pageView-segments',
      emails: 'websiteAdmin-pageView-emails',
      customEmails: 'websiteAdmin-pageView-customEmails',
      automations: 'websiteAdmin-pageView-automations',
      payouts: 'websiteAdmin-pageView-payouts',
      reports: 'websiteAdmin-pageView-reports',
      devolutions: 'websiteAdmin-pageView-devolutions',
      websitePayments: 'websiteAdmin-pageView-websitePayments',
      emenu: 'websiteAdmin-pageView-emenu'
    }
  },
  login: 'login',
  startSession: 'start-session'
})
