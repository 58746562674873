import {useEffect} from 'react'
import isScrapping from 'App/helpers/misc/isScrapping'

export default function SendPulseWebPushIntegration(props) {
  const {integrations: config} = props

  useEffect(() => {
    if (
      !isScrapping &&
      config &&
      config.integrations &&
      config.integrations.sendpulse &&
      config.integrations.sendpulse.apiKey
    ) {
      const script = document.createElement('script')
      const url = `//web.webpushs.com/js/push/${config.integrations.sendpulse.apiKey}.js`

      script.src = url
      script.async = true

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }
  }, [config])

  return null
}
