import getJWTData from './getJWTData'
import onSessionSaved from './onSessionSaved'

export default function({token, refreshToken}) {
  if (!token) {
    localStorage.removeItem('justo_jwt_token')
    localStorage.removeItem('justo_jwt_refresh')
    localStorage.removeItem('justo_jwt_data')

    // session changed

    return onSessionSaved(null)
  }

  localStorage.setItem('justo_jwt_token', token)

  const data = getJWTData(token)
  localStorage.setItem('justo_jwt_data', JSON.stringify(data))

  if (refreshToken) {
    localStorage.setItem('justo_jwt_refresh', refreshToken)

    // session changed
    return onSessionSaved(data)
  }
}
