import React from 'react'
import * as Sentry from '@sentry/react'
import {Integrations} from '@sentry/tracing'
import {Dedupe as DedupeIntegration} from '@sentry/integrations'
import getEnv from 'App/Root/getEnv'
import {ignoreErrors, denyUrls} from './ignoreRules'
import {eventWasInformed, eventIsFromGTM} from './beforeSendEvent'

const isSentryEnabled = () => getEnv() === 'prod' || getEnv() === 'dev'

export function initSentry() {
  if (isSentryEnabled()) {
    //Default DSN is only an example
    const sentryDSN =
      process.env.REACT_APP_SENTRY_DSN ||
      'https://a5f7f569eb5140d097bc8da6034121a8@o522220.ingest.sentry.io/5633605'
    Sentry.init({
      dsn: sentryDSN,
      integrations: [new Integrations.BrowserTracing(), new DedupeIntegration()],
      release: 'justo@' + (process.env.REACT_APP_COMMIT_HASH || 'local'),
      environment: getEnv(),
      tracesSampleRate: 0.05,
      sampleRate: 0.2,
      beforeSend(event, hint) {
        const eventFromGTM = eventIsFromGTM(event, hint)
        return eventWasInformed(event) || !eventFromGTM ? null : eventFromGTM ? eventFromGTM : event
      },
      ignoreErrors: ignoreErrors,
      denyUrls: denyUrls
    })
  }
}

export function setSentryUser(user) {
  Sentry.setUser({email: user.email})
}

export function clearSentryUser(user) {
  Sentry.setUser(null)
}

export default function withSentry(App) {
  return function SentryApp() {
    if (isSentryEnabled()) {
      const WithProfiler = Sentry.withProfiler(App)
      return (
        <Sentry.ErrorBoundary>
          <WithProfiler />
        </Sentry.ErrorBoundary>
      )
    }
    return <App />
  }
}
