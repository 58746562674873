import getEnv from './getEnv'
import getCustom from './getCustom'

export const urls = {
  local: '',
  dev: 'https://web.bejusto.com',
  prod: 'https://web.getjusto.com'
}

export default getCustom('www') || urls[getEnv()]
