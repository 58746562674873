import {useEffect} from 'react'
import isScrapping from 'App/helpers/misc/isScrapping'
import LogRocket from 'logrocket'
import useCookiesAccepted from 'App/hooks/useCookiesAccepted'

export default function LogRocketIntegration(props) {
  const {integrations: config} = props
  const cookies = useCookiesAccepted()

  useEffect(() => {
    if (
      !isScrapping &&
      config &&
      config.integrations &&
      config.integrations.logRocketId &&
      cookies
    ) {
      LogRocket.init(config.integrations.logRocketId)
    }
  }, [config, cookies])

  return null
}
