import React from 'react'
import MetaTags from 'react-meta-tags'

export default function BaseMeta(props) {
  const title = 'Justo - Un sistema de pedidos en linea para tu restaurant'
  const description =
    'Tu propia aplicación de pedidos online y un sitio web fácil de usar. Con potentes herramientas que te ayudan a mejorar la lealtad y retener clientes.'
  return (
    <>
      <MetaTags>
        <title>{title}</title>
        <meta name="facebook-domain-verification" content="f8hnye3ok2fq7tcss14xwy4a078ljp" />
        <meta name="description" content={description} />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content={`https://${window.location.host}${window.location.pathname}`}
        />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />

        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/images/favicon/android-icon-192x192.png"
        />
        <link rel="icon" type="image/png" sizes="32x32" href="/images/favicon/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="96x96" href="/images/favicon/favicon-96x96.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/images/favicon/favicon-16x16.png" />
      </MetaTags>
    </>
  )
}
