import React from 'react'
import styles from './styles.css'
import Icon from 'react-icons/lib/md/lock'

export default function PermissionsError(props) {
  const {error} = props
  let errorDescription = 'No hay más información del error'

  if (error.errors && error.errors[0]) {
    errorDescription = error.errors[0].message
  }

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <Icon size={24} />
      </div>
      <div className={styles.label}>No tienes permisos</div>
      <div className={styles.description}>{errorDescription}</div>
    </div>
  )
}
