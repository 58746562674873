import ReactGA from 'react-ga'
import GA4React from 'ga-4-react'
import trackPageView from 'App/helpers/track/trackPageView'
import isScrapping from 'App/helpers/misc/isScrapping'
import useEffect from 'App/hooks/useEffect'
import useRouter from 'App/hooks/useRouter'
import useCookiesAccepted from 'App/hooks/useCookiesAccepted'

export default function GoogleAnalytics(props) {
  const {integrations: config} = props
  const cookies = useCookiesAccepted()

  const getCode = () => {
    if (isScrapping) return

    if (!config) return
    if (!config.integrations) return
    if (!config.integrations.googleAnalyticsId) return

    return config.integrations.googleAnalyticsId
  }

  const getCodeV4 = () => {
    if (isScrapping) return

    if (!config) return
    if (!config.integrations) return
    if (!config.integrations.googleAnalyticsV4Id) return

    return config.integrations.googleAnalyticsV4Id
  }

  const {location} = useRouter()

  useEffect(() => {
    const code = getCode()
    const codeV4 = getCodeV4()
    if ((!code && !codeV4) || !cookies) return

    if (code) {
      ReactGA.initialize(code)
      ReactGA.plugin.require('ec')
    }

    if (!code && codeV4) {
      const ga4react = new GA4React(codeV4)
      ga4react.initialize().then(
        ga4 => {
          ga4.pageview(location.pathname)
        },
        (err: Error) => {
          console.error(err)
        }
      )
    }

    trackPageView(location.pathname)
  }, [cookies])

  return null
}
