import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga'
import GA4React from 'ga-4-react'

export default function(pathname) {
  if (!pathname.includes('/virtual')) {
    ReactPixel.pageView()
  }
  ReactGA.pageview(pathname)
  if (GA4React.isInitialized()) {
    const ga4 = GA4React.getGA4React()
    if (ga4) {
      ga4.pageview(pathname)
    }
  }
}
