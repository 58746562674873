import React from 'react'
import gql from 'graphql-tag'
import ReactPixel from 'react-facebook-pixel'
import loadIntercom from './loadIntercom'
import TawkTo from './TawkTo'
import isScrapping from 'App/helpers/misc/isScrapping'
import useQuery from 'apollo-hooks/lib/useApolloQuery'
import useEffect from 'App/hooks/useEffect'
import Zendesk from 'react-zendesk'
import {ZendeskAPI} from 'react-zendesk'

export default function UserChat(props) {
  const {integrations: config} = props

  const getIntercomAppId = () => {
    if (isScrapping) return

    if (!config) return null
    if (!config.integrations) return null
    if (!config.integrations.intercomId) return null
    return config.integrations.intercomId
  }

  const getPixelId = () => {
    if (isScrapping) return

    if (!config) return null
    if (!config.integrations) return null
    if (!config.integrations.facebookPixelId) return null
    return config.integrations.facebookPixelId
  }

  const getJustoAdsPixelId = () => {
    if (isScrapping) return
    if (!config) return null
    if (!config.justoAdsPixelId) return null
    return config.justoAdsPixelId
  }

  const getTawkToId = () => {
    if (isScrapping) return

    if (!config) return null
    if (!config.integrations) return null
    if (!config.integrations.tawkToId) return null
    return config.integrations.tawkToId
  }

  const getZendeskId = () => {
    if (isScrapping) return

    if (!config) return null
    if (!config.integrations) return null
    if (!config.integrations.zendeskId) return null
    return config.integrations.zendeskId
  }

  const omit = !getTawkToId() && !getPixelId() && !getIntercomAppId() && !getZendeskId()

  const {me} = useQuery({
    query: gql`
      query getUserForChats {
        me {
          _id
          email
          name
          phone
          profile {
            firstName
            lastName
          }
          createdAt
        }
      }
    `,
    fetchPolicy: 'cache-first',
    omit,
    partial: true
  })

  const getIntercomUser = () => {
    const user = me
    if (!user) return
    return {
      user_id: user._id,
      email: user.email,
      created_at: Math.round(new Date(user.createdAt) * 0.001),
      name: user.name,
      first_name: user.profile.firstName,
      last_name: user.profile.lastName,
      user_hash: user.intercomHash
    }
  }

  const getZendeskUser = () => {
    const user = me
    if (!user) return
    ZendeskAPI('webWidget', 'identify', {name: user.name, email: user.email})
  }

  const getPixelUser = () => {
    const user = me
    if (!user) return
    return {
      em: user.email,
      ph: user.phone ? user.phone.replace('+', '') : null,
      fn: user.profile.firstName,
      ln: user.profile.lastName
    }
  }

  const startIntegrations = async () => {
    if (getIntercomAppId()) {
      await loadIntercom()
      window.Intercom('boot', {
        app_id: getIntercomAppId(),
        ...getIntercomUser()
      })
    }
    if (getZendeskId()) {
      getZendeskUser()
    }
    const pixelId = getPixelId()
    if (pixelId) {
      ReactPixel.init(pixelId, getPixelUser())
    }
    const justoAdsPixelId = getJustoAdsPixelId()
    if (justoAdsPixelId && justoAdsPixelId !== pixelId) {
      ReactPixel.init(justoAdsPixelId, getPixelUser())
    }
    ReactPixel.pageView()
  }

  const restartIntegrations = () => {
    stopIntegrations()
    startIntegrations()
  }

  const stopIntegrations = () => {
    if (getIntercomAppId()) {
      window.Intercom('shutdown')
    }
  }

  useEffect(() => {
    startIntegrations()
    return () => {
      stopIntegrations()
    }
  }, [])

  useEffect(() => {
    restartIntegrations()
  }, [me && me._id])

  return (
    <>
      {getTawkToId() ? <TawkTo id={getTawkToId()} /> : null}
      {getZendeskId() ? <Zendesk zendeskKey={getZendeskId()} /> : null}
    </>
  )
}
