export default function({
  backgroundColor,
  textColor,
  lighterBackgroundColor,
  backgroundBorderColor,
  color,
  isDarkBackground
}) {
  if (!isDarkBackground) return ''

  return `
	.os-input-text {
		background: ${backgroundBorderColor};
		color: ${textColor};
    border-color: #333;
	}

  .os-input-text:focus {
    border-color: ${color};
  }

	.orion-select__control * {
	  color: ${textColor};
	}

	.orion-select__option {
	  color: ${lighterBackgroundColor};
	}

	.orion-select__option > div > div {
	  color: ${lighterBackgroundColor};
	}

	.orion-select__single-value {
	  color: ${textColor} !important;
	}

	.orion-select__single-value * {
	  color: ${textColor} !important;
	}

  .orion-select__control {
    background: ${backgroundBorderColor};
    border-color: #333;
  }

  .orion-select__control--is-focused {
    border-color: ${color} !imporant;
  }

  .orion-select__option--is-selected > div > div  {
	   color: ${textColor};
	}
  .orion-select__option--is-selected {
    color: ${textColor};
  }
	`
}
