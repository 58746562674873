import React from 'react'
import styles from './styles.css'

export default function NotFound() {
  return (
    <div className={styles.notFoundContainer}>
      <img src="/images/not-found.png" alt="No existe" width="280" />
      <h1 className={styles.title}>
        Oops! Al parecer no tienes permisos para ver esta sección o no existe
      </h1>
      <span className={styles.subTitle}>Contáctate con el administrador si necesitas entrar</span>
    </div>
  )
}
