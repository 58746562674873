import useScript from 'App/hooks/useScript'

export default function Chat(props) {
  const {jivoChatId} = props
  const url = `https://code.jivosite.com/widget/${jivoChatId}`
  useScript(url, () => {
    console.log('Jivo chat loaded')
  })
  return null
}
