import gql from 'graphql-tag'
import useQuery from 'apollo-hooks/lib/useApolloQuery'
import useInitialData from 'App/hooks/useInitialData'

export default function(websiteId) {
  const initialData = useInitialData()

  /**
   * Si es que se cambia esta query hay que cambiar la query desde el proxy también
   */
  const {integrations, seo, website, design, navbar} = useQuery({
    query: gql`
      query getWebsiteLayoutConfiguration_cached($websiteId: ID) {
        website(websiteId: $websiteId) {
          _id
          name
          description
          fontsCss
          authOnlyJustoUsers
          country {
            name
            locale
          }
        }

        design: sectionConfiguration(section: "design", websiteId: $websiteId) {
          _id
          design {
            primaryColor
            customCss
            backgroundColor
            productCardDesign
            originalImageAspectRatio
          }
        }

        navbar: sectionConfiguration(section: "navbar", websiteId: $websiteId) {
          _id
          navbar {
            backgroundColor
            textColor
            buttonColor
            upperBarColor
            hintColor
          }
        }

        seo: sectionConfiguration(section: "seo", websiteId: $websiteId) {
          _id
          seo {
            title
            description
            image {
              _id
              url
            }
            favicon {
              _id
              url: resizedURL(width: 144, height: 144, resizeMode: "contain", format: "png")
            }
          }
        }

        integrations: sectionConfiguration(section: "integrations", websiteId: $websiteId) {
          _id
          integrations {
            jivoChatId
            facebookChatPageId
            supportWhatsappNumber
            googleAnalyticsId
            googleAnalyticsV4Id
            intercomId
            facebookPixelId
            tawkToId
            tagManagerId
            zendeskId
            logRocketId
            highlightId
            insidersUrl
            fidelityToolsPush
            indigitallWebPush
            sendpulse {
              apiKey
            }
          }
          justoAdsPixelId
        }
      }
    `,
    variables: {websiteId},
    omit: !!initialData
  })

  if (initialData) return initialData

  return {
    integrations,
    seo,
    website,
    design,
    navbar
  }
}
