import * as Sentry from '@sentry/react'
import JustoError from './JustoError'

const logException = (exception, data = {}) => {
  Sentry.captureException(exception, {
    tags: {
      errorLevel: 'JustoException'
    },
    level: 'error',
    extra: data
  })
  console.error(`Justo<exception>`, exception, data)
}

const logError = (message, data = {}) => {
  Sentry.captureException(new JustoError(message, data), {
    tags: {
      errorLevel: 'JustoError'
    },
    level: 'error',
    extra: data
  })
  console.error(`Justo<error>`, message, data)
}

const logWarn = (message, data = {}) => {
  Sentry.captureMessage(new JustoError(message, data, 'warning'), {
    tags: {
      errorLevel: 'JustoWarning'
    },
    level: 'warning',
    extra: data
  })
  console.warn(`Justo<warn>`, message, data)
}

const logDebug = (message, data = {}) => {
  console.log(`Justo<debug>`, message, data)
  console.trace()
}

const logInfo = (message, data = {}) => {
  console.info(`Justo<info>`, message, data)
}

const log = (message, data = {}) => {
  console.info(`Justo<log>`, message, data)
  console.trace()
}

export default {
  local: {
    exception: logInfo,
    error: log,
    warn: log,
    info: logInfo,
    debug: logDebug
  },
  prod: {
    exception: logException,
    error: logError,
    warn: logWarn,
    info: logInfo,
    debug: logDebug
  },
  dev: {
    exception: logException,
    error: logError,
    warn: logWarn,
    info: logInfo,
    debug: logDebug
  }
}
