import Loading from '@justodev/parts/lib/components/Loading'
import React from 'react'
import styles from './styles.css'

export default function LayoutLoading(props) {
  return (
    <div className={styles.loading}>
      <Loading />
    </div>
  )
}
