import saveJWT from './saveJWT'
import getAuthURL from 'App/Root/getAuthURL'

export default async function() {
  if (!localStorage.getItem('justo_jwt_refresh')) {
    console.log('No refresh token. Will reset Session')
    saveJWT({})
    return
  }

  const response = await fetch(`${getAuthURL()}/refresh-token`, {
    method: 'POST',
    headers: {
      'X-ORION-REFRESH': localStorage.getItem('justo_jwt_refresh') || null
    }
  })

  const result = await response.json()

  if (result.error || !result.token) {
    console.log('Error refreshing JWT. Will reset Session')
    saveJWT({})
    return
  }

  saveJWT({token: result.token})
}
