import React, {Fragment} from 'react'
import useWebsiteId from 'App/hooks/useWebsiteId'
import isInSalesPoint from 'App/helpers/misc/isInSalesPoint'
import BaseStyles from './BaseStyles'
import useRouter from 'App/hooks/useRouter'
import PageMeta from './PageMeta'
import BaseMeta from './BaseMeta'
import SetLocale from './WebsiteData/Locale/SetLocale'
import WebsiteData from './WebsiteData'
import useEffect from 'App/hooks/useEffect'
import useSessionStorageState from 'App/hooks/useSessionStorageState'
import {WRAPPED_WEBSITE_ID_KEY} from 'src/App/components/WrappedWebsite/constants'
import getQueryParam from '@justodev/parts/lib/helpers/getQueryParam'

export default function Layout(props) {
  const {location} = useRouter()
  const websiteId = useWebsiteId()
  // eslint-disable-next-line
  const [whatsappBusiness, setWhatsappBusiness] = useSessionStorageState('whatsapp-business')
  const [wrappedWebsiteId, _] = useSessionStorageState(WRAPPED_WEBSITE_ID_KEY)

  useEffect(() => {
    const referrer = getQueryParam('referrer')
    if (referrer === 'whatsapp-business') {
      setWhatsappBusiness(true)
    }
  }, [])

  let websiteData = null
  const isSalesPoint = location.pathname.startsWith('/admin/sales-point') || isInSalesPoint()
  const isInAdmin =
    location.pathname.startsWith('/admin') || location.pathname.startsWith('/support-center')

  if (isSalesPoint || !websiteId) {
    if (wrappedWebsiteId) {
      websiteData = <WebsiteData websiteId={wrappedWebsiteId} />
    } else {
      websiteData = (
        <Fragment>
          <BaseStyles />
          <BaseMeta />
          <SetLocale locale="es_CL" />
        </Fragment>
      )
    }
  } else if (isInAdmin) {
    websiteData = (
      <Fragment>
        <BaseStyles />
        <WebsiteData onlyMeta websiteId={websiteId} />
      </Fragment>
    )
  } else {
    // in website
    websiteData = <WebsiteData websiteId={websiteId} />
  }

  return (
    <PageMeta>
      <Fragment>
        {props.children}
        {websiteData}
      </Fragment>
    </PageMeta>
  )
}
