import useEffect from 'App/hooks/useEffect'
import numeral from 'numeral'
import 'App/i18n/formatNumber/locales'

export default function SetLocale(props) {
  const {locale} = props
  useEffect(
    () => {
      numeral.locale(locale)
      global.currentLocale = locale
      // dayjs locale is set from App/helpers/dayjs to avoid racing conditions
      // with the lib plugins
    },
    [locale]
  )
  return null
}
