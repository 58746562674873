const loadedScripts = {}

const loadScript = function(src, onFirstLoad) {
  if (loadedScripts[src]) return loadedScripts[src]

  console.log('will load script', src)
  loadedScripts[src] = new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.onload = () => {
      loadedScripts[src] = 'ok'
      if (onFirstLoad) {
        onFirstLoad()
      }
      resolve()
    }
    script.src = src

    document.head.appendChild(script)
  })

  return loadedScripts[src]
}

export function removeScript(src) {
  const allScripts = document.getElementsByTagName('script')
  const targetScript = Object.values(allScripts).find(script => script.src === src)
  if (targetScript) {
    targetScript.remove()
    delete loadedScripts[src]
  }
}

export default function useScript(src, onFirstLoad) {
  const result = loadScript(src, onFirstLoad)
  if (result === 'ok') return

  throw result
}
