import {Context} from 'App/Root/GlobalState'
import {useContext} from 'react'

export const getSavedValue = function(key, defaultValue) {
  const savedValue = sessionStorage.getItem(key)
  if (savedValue === null) {
    return defaultValue
  } else {
    return JSON.parse(savedValue)
  }
}

export default function(key, defaultValue) {
  const value = getSavedValue(key, defaultValue)
  const forceUpdate = useContext(Context)

  const setValue = newValue => {
    sessionStorage.setItem(key, JSON.stringify(newValue))
    forceUpdate()
  }

  return [value, setValue]
}
