import React from 'react'
import SessionContext from 'App/helpers/auth/SessionContext'
import useOnEvent from 'react-app-events/lib/useOnEvent'
import useState from 'App/hooks/useState'
import {setMixpanelUserId, sendMixpanelUserInfo} from 'App/helpers/mixpanel'
import getJWTSavedData from './apollo/getJWTSavedData'
import {sendPosthogUserInfo, setPosthogUserId} from 'App/helpers/posthog'

export default function SessionContextProvider(props) {
  const jwtData = getJWTSavedData()

  const [session, setSession] = useState(jwtData)

  useOnEvent('saveSession', newSession => {
    setSession(newSession)

    if (newSession) {
      setMixpanelUserId(newSession.userId)
      setPosthogUserId(newSession.userId)
      sendMixpanelUserInfo(newSession)
      sendPosthogUserInfo(newSession)
    }
  })

  return <SessionContext.Provider value={session || {}}>{props.children}</SessionContext.Provider>
}
