import getQueryParam from '@justodev/parts/lib/helpers/getQueryParam'
import generateId from '../misc/generateId'

export default function() {
  const paramDeviceId = getQueryParam('deviceId')
  if (paramDeviceId) {
    localStorage.setItem('deviceId', paramDeviceId)
    return paramDeviceId
  }

  const savedId = localStorage.getItem('deviceId')
  if (savedId) return savedId

  const generatedId = generateId(50)

  localStorage.setItem('deviceId', generatedId)
  return generatedId
}
