import getEnv from 'App/Root/getEnv'
import getLocalStorageState from 'App/hooks/getLocalStorageState'
import logger from 'App/helpers/logger'
import posthog from 'posthog-js'

const getCookies = () => {
  const prompCookies = getLocalStorageState('prompCookies')
  return prompCookies ? JSON.parse(prompCookies).userClickAccepted : false
}

export const setPosthogUserId = userId => {
  if ((getEnv() !== 'prod' && getEnv() !== 'dev') || !getCookies()) return

  try {
    posthog.identify(userId)
  } catch {
    logger.exception('Posthog not loaded')
  }
}

export const sendPosthogUserInfo = userInfo => {
  if ((getEnv() !== 'prod' && getEnv() !== 'dev') || !getCookies()) return

  try {
    posthog.people.set(userInfo)
  } catch {
    logger.exception('Posthog not loaded')
  }
}

export const sendPosthogData = (eventType, eventProperties = {}) => {
  if ((getEnv() !== 'prod' && getEnv() !== 'dev') || !getCookies()) return

  eventProperties = {
    ...eventProperties
  }
  try {
    posthog.capture(eventType, eventProperties)
  } catch {
    logger.exception('Posthog not loaded')
  }
}

export const EVENTS = Object.freeze({
  clicked: {
    productChangeAmount: 'clicked-productChangeAmount',
    addressComponentOrder: 'clicked-addressComponentOrder',
    checkoutLogin: 'clicked-checkoutLogin',
    upselling: 'clicked-upselling',
    login: 'clicked-login',
    cartRemoveProduct: 'clicked-cartRemoveProduct',
    cartContinueToCheckout: 'clicked-cartContinueToCheckout',
    cart: 'clicked-cart',
    search: 'clicked-search',
    product: 'clicked-product',
    productAddToCart: 'clicked-productAddToCart',
    help: 'clicked-help',
    cancelOrder: 'clicked-cancel-order',
    changePreferredStore: 'clicked-change-preferred-store',
    omitLogin: 'clicked-omit-login',
    editProduct: 'clicked-edit-product',
    saveEditProduct: 'clicked-save-edit-product'
  },
  product: {
    setQuantityTo0: 'update-quantity-0-product'
  },
  coupon: {
    apply: 'coupon-apply',
    failed: 'coupon-failed'
  },
  address: {
    failed: 'address-failed',
    accept: 'address-accept'
  },
  pay: {
    passValidations: 'pay-passValidations',
    failed: 'pay-failed',
    successful: 'pay-successful'
  },
  login: 'login',
  startSession: 'start-session'
})
