import isMainDomain from 'App/helpers/misc/domain/isMainDomain'

export default function() {
  if (isMainDomain()) return null

  if (localStorage.getItem('salesPointDomain')) {
    return localStorage.getItem('salesPointDomain')
  } else {
    return window.location.hostname
  }
}
