export default function({
  backgroundColor,
  textColor,
  descriptionTextColor,
  lighterBackgroundColor,
  backgroundBorderColor,
  color,
  navbar
}) {
  return `

:root {
  --text: ${textColor};
  --background-color: ${backgroundColor};
  --description-text-color: ${descriptionTextColor};
  --primary-color: ${color};
  --primary-color-10: ${color}10;
  --lighter-background-color: ${lighterBackgroundColor};
  --background-border-color: ${backgroundBorderColor};
  --checkout-blue: #1063ff;

  --navbar-background-color: ${navbar.backgroundColor};
  --navbar-button-color: ${navbar.buttonColor};
  --navbar-hint-color: ${navbar.hintColor};
  --navbar-text-color: ${navbar.textColor};
  --navbar-upperbar-color: ${navbar.upperBarColor};

}

body {
  background-color: ${backgroundColor};
  color: ${textColor};
}

.textColor {
  color: ${textColor};
}

.descriptionTextColor {
  color: ${descriptionTextColor};
}

.backgroundColor {
  background-color: ${backgroundColor};
}

.lighterBackgroundColor {
  background-color: ${lighterBackgroundColor};
}

.secondaryBackgroundColor {
	background-color: ${backgroundBorderColor};
}

.backgroundBorderColor {
  border-color: ${backgroundBorderColor};
}

.content {
  background-color: ${lighterBackgroundColor};
  color: ${textColor};
}

.divider {
	background-color: ${backgroundBorderColor};
}

.primaryColor {
  color: ${color};
}

.primaryBackgroundColor {
  background-color: ${color};
}

.primaryBorderColor {
  border-color: ${color};
}

.orion_primary, .orion_primary:active, .orion_primary:hover {
  background-color: ${color};
}

a {
  color: ${color};
}


`
}
