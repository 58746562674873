import React from 'react'
import getContrastColor from 'App/helpers/misc/getContrastColor'
import getColors from './getColors'
import getInputStyles from './getInputStyles'
import getFont from './getFont'

export default function Styles(props) {
  const {website, design: config, navbar: navbarColors} = props

  if (!website) {
    return null
  }

  const isInAdmin =
    location.pathname.startsWith('/admin') || location.pathname.startsWith('/support-center')

  if (isInAdmin) {
    return (
      <>
        <style>{getFont()}</style>
      </>
    )
  }

  const design = config ? config.design || {} : {}
  const navbar = navbarColors ? navbarColors.navbar || {} : {}
  if (!design.customCss) design.customCss = ''
  if (!design.primaryColor) design.primaryColor = '#2866ff'
  if (!design.backgroundColor) design.backgroundColor = '#ffffff'

  if (!navbar.backgroundColor) navbar.backgroundColor = '#ffffff'
  if (!navbar.buttonColor) navbar.buttonColor = '#000000'
  if (!navbar.hintColor) navbar.hintColor = '#fdec4f'
  if (!navbar.textColor) navbar.textColor = '#111111'
  if (!navbar.upperBarColor) navbar.upperBarColor = '#ffffff'

  const color = design.primaryColor
  const backgroundColor = design.backgroundColor
  const theCss = design.customCss
  const isDarkBackground = getContrastColor(backgroundColor || '#ffffff') === '#ffffff'
  global.isDarkBackground = isDarkBackground
  const lighterBackgroundColor = isDarkBackground ? '#222' : '#fff'
  const descriptionTextColor = isDarkBackground ? '#aaa' : '#999'
  const textColor = isDarkBackground ? '#fff' : '#111'
  const backgroundBorderColor = isDarkBackground ? '#2b2b2b' : '#eee'

  // define global website styles as css variables
  document.documentElement.style.setProperty('--websitePrimaryColor', color)

  const options = {
    backgroundColor,
    textColor,
    lighterBackgroundColor,
    backgroundBorderColor,
    color,
    isDarkBackground,
    descriptionTextColor,
    navbar
  }
  const colorCss = getColors(options)
  const selectCss = getInputStyles(options)

  return (
    <>
      <style>{`${website.fontsCss} ${colorCss} ${selectCss}`}</style>
      {theCss ? <style>{theCss}</style> : null}
    </>
  )
}
