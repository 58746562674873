import FingerprintJS from '@fingerprintjs/fingerprintjs'

let fingerprint = null

;(async () => {
  const fp = await FingerprintJS.load()

  const result = await fp.get()

  fingerprint = result.visitorId
})()

export default function() {
  return fingerprint
}
