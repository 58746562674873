import gql from 'graphql-tag'
import useWebsiteId from 'App/hooks/useWebsiteId'
import useInitialDataOrQuery from './useInitialDataOrQuery'

// returns only the most general website information
export default function() {
  const websiteId = useWebsiteId()
  const {website} = useInitialDataOrQuery({
    query: gql`
      query getWebsiteInfo($websiteId: ID) {
        website(websiteId: $websiteId) {
          _id
          name
          description
          countryCode
          timezone
          country {
            canSendDevolutionDataEmail
            locale
          }
          foodCategoriesCodes
          baseURL
          businessType
        }
      }
    `,
    variables: {websiteId},
    fetchPolicy: 'cache-first',
    omit: !websiteId
  })

  if (!website) return {}

  return {
    websiteId: website._id,
    websiteName: website.name,
    websiteDescription: website.description,
    websiteCountryCode: website.countryCode,
    websiteCanSendDevolutionDataEmail: website.country.canSendDevolutionDataEmail,
    websiteCategories: website.foodCategoriesCodes,
    websiteTimezone: website.timezone,
    websiteLocale: website.country.locale,
    websiteBaseURL: website.baseURL,
    websiteBusinessType: website.businessType
  }
}
