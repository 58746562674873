import React, {useContext} from 'react'
import MetaTags from 'react-meta-tags'
import PageMetaContext from 'App/helpers/websites/PageMetaContext'

export default function Styles(props) {
  const {website, seo: config} = props

  const {subtitle, description, image, schema} = useContext(PageMetaContext)

  if (!website) return null

  const seo = {
    title: website.name,
    description: `Pide ${website.name}`,
    ...config.seo
  }
  const pageTitle = subtitle ? `${subtitle} - ${seo.title}` : seo.title
  const pageDescription = description || seo.description
  const pageImage = image || seo.image
  return (
    <MetaTags>
      <title>{pageTitle}</title>
      <meta name="description" content={pageDescription} />
      <meta property="og:type" content="website" />
      <meta
        property="og:url"
        content={`https://${window.location.host}${window.location.pathname}`}
      />
      <meta property="og:title" content={pageTitle} />
      <meta property="og:description" content={pageDescription} />
      {pageImage && <meta property="og:image" content={pageImage.url} />}
      {seo.favicon && <link rel="shortcut icon" href={seo.favicon.url} />}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{__html: schema ? JSON.stringify(schema, null, 2) : ''}}
      />
    </MetaTags>
  )
}
