import dayjs from 'dayjs'
import AdvancedFormat from 'dayjs/plugin/advancedFormat'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'
import UTC from 'dayjs/plugin/utc'
import Timezone from 'dayjs/plugin/timezone' // dependent on utc plugin
import LocaleData from 'dayjs/plugin/localeData'
import 'dayjs/locale/es'
import RelativeTime from 'dayjs/plugin/relativeTime'
import CustomParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(AdvancedFormat)
dayjs.extend(LocalizedFormat)
dayjs.extend(UTC)
dayjs.extend(Timezone)
dayjs.extend(LocaleData)
dayjs.extend(RelativeTime)
dayjs.extend(CustomParseFormat)

// To detect the locale dynamically, we would need to add a babel-preset to
// allow dynamic imports, where in line 7 _es_ is a variable. For the time
// being I think it's better to leave it as it is, because there is still
// consideration towards going back to CRA as a bundler, and it doesn't allow a
// .babelrc config.
// I don't think differenciating between es locales makes sense, as this will
// be used to translate months, name of week days, etc.
dayjs.locale('es')

export default dayjs
