import gql from 'graphql-tag'
import getDomain from 'App/helpers/websites/getDomain'
import WebsiteIdContext from 'App/Pages/WebsiteAdmin/WebsiteIdContext'
import {useContext} from 'react'
import useInitialDataOrQuery from './useInitialDataOrQuery'
import getQueryParam from './useURLState/getQueryParam'

// para que se guarde solo al cargar la pagina y quede asi la variable para siempre
const queryWebsiteId = getQueryParam({location: window.location, key: 'websiteId'})

export default function useWebsiteId() {
  const adminWebsiteId = useContext(WebsiteIdContext)

  const {website} = useInitialDataOrQuery({
    query: gql`
      query getWebsiteIdFromDomain_cached($domain: String) {
        website(domain: $domain) {
          _id
          domain
          logo {
            _id
            url
          }
        }
      }
    `,
    variables: {domain: getDomain()},
    fetchPolicy: 'cache-first',
    omit: !getDomain() || adminWebsiteId || queryWebsiteId
  })

  if (adminWebsiteId) return adminWebsiteId
  if (queryWebsiteId) return queryWebsiteId
  if (!getDomain()) return null
  if (!website) return null

  return website._id
}
