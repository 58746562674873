import React, {useState} from 'react'
import {connectToParent} from 'penpal'
import {useEffect, useRef} from 'react'
import isInSubdomain from 'App/helpers/misc/domain/isInSubdomain'
import {useLocation, useHistory} from 'react-router-dom'
import saveJWT from 'App/Root/apollo/saveJWT'
import getQueryParam from 'App/hooks/useURLState/getQueryParam'

export function useIframeConnection() {
  const requiresSession = getQueryParam({location: window.location, key: 'requiresSession'})
  const parentRef = useRef()
  const location = useLocation()
  const history = useHistory()
  const [sessionReady, setSessionReady] = useState(false)

  useEffect(() => {
    if (!isInSubdomain('websiteadminframe')) return
    const connection = connectToParent({
      // Methods child is exposing to parent.
      methods: {
        async setSession(sessionData) {
          await saveJWT({token: sessionData.jwt, refreshToken: sessionData.refresh})
          setSessionReady(true)
        },
        getPath() {
          return window.location.pathname
        },
        navigate(path) {
          history.push(path)
        }
      }
    })

    console.log('Connecting to parent iframe...')
    connection.promise.then(parent => {
      console.log('Connected to parent iframe')
      parentRef.current = parent
    })

    return () => {
      connection.destroy()
    }
  }, [])

  useEffect(() => {
    if (!parentRef.current) return
    parentRef.current.navigate(location.pathname)
  }, [location.pathname])

  return requiresSession ? sessionReady : true
}

export default function ViteConnection(props) {
  const ready = useIframeConnection()
  if (!ready) return null
  return <>{props.children}</>
}
