import createApolloClient from './createApolloClient'
import services from 'App/services'
import getEnv from 'App/Root/getEnv'

const env = getEnv()
const clients = {}

for (const key in services) {
  const service = services[key]
  const {urls} = service
  const url = urls[env]
  clients[key] = createApolloClient({
    endpointURL: url,
    useSubscriptions: service.useSubscriptions
  })
}

export default clients
