import React from 'react'
import HooksApolloProvider from 'apollo-hooks/lib/ApolloProvider'
import {ApolloProvider} from 'react-apollo'
import clients from './clients'

export default function ApolloServicesClients(props) {
  return (
    <HooksApolloProvider {...clients} client={clients.main}>
      <ApolloProvider client={clients.main}>{props.children}</ApolloProvider>
    </HooksApolloProvider>
  )
}
