import React from 'react'
import Styles from './Styles'
import WithUserChats from './WithUserChats'
import GA from './GA'
import WhatsappChat from './WhatsappChat'
import JivoChat from './JivoChat'
import FacebookChat from './FacebookChat'
import Meta from './Meta'
import useConfiguration from './useConfiguration'
import Locale from './Locale'
import GoogleTagManager from './GoogleTagManager'
import LogRocketIntegration from './LogRocketIntegration'
import SendPulseWebPushIntegration from './SendPulseWebPushIntegration'
import IndigitallWebPushIntegration from './IndigitallWebPushIntegration'
import FidelityToolsIntegration from './FidelityToolsIntegration'
import InsidersIntegration from './InsidersIntegration'
import HighlightIntegration from './HighlightIntegration'
import WithAuth from './WithAuth'

export default function WebsiteLayout(props) {
  const {websiteId, onlyMeta} = props

  const data = useConfiguration(websiteId)

  const defaultComponent = (
    <>
      <WithAuth {...data} />
      <Meta {...data} />
      <Locale {...data} />
    </>
  )

  if (onlyMeta) {
    return defaultComponent
  }

  return (
    <>
      <Styles {...data} />
      <WithUserChats {...data} />
      <JivoChat {...data} />
      <FacebookChat {...data} />
      <WhatsappChat {...data} />
      <GA {...data} />
      <GoogleTagManager {...data} />
      {defaultComponent}
      <LogRocketIntegration {...data} />
      <SendPulseWebPushIntegration {...data} />
      <IndigitallWebPushIntegration {...data} />
      <FidelityToolsIntegration {...data} />
      <InsidersIntegration {...data} />
      <HighlightIntegration {...data} />
    </>
  )
}
