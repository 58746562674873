import numeral from 'numeral'
import standardLocale from './standardLocale'

numeral.register('locale', 'es_CL', {
  ...standardLocale,
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  currency: {
    symbol: '$'
  }
})

numeral.register('locale', 'es_PE', {
  ...standardLocale,
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  currency: {
    symbol: 'S/ '
  }
})

numeral.register('locale', 'es_MX', {
  ...standardLocale,

  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  currency: {
    symbol: '$'
  }
})

numeral.register('locale', 'es_CO', {
  ...standardLocale,

  delimiters: {
    thousands: '.',
    decimal: ','
  },
  currency: {
    symbol: '$'
  }
})

numeral.register('locale', 'en_US', {
  ...standardLocale,
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  currency: {
    symbol: '$'
  }
})

numeral.register('locale', 'es_US', {
  ...standardLocale,
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  currency: {
    symbol: '$'
  }
})

numeral.register('locale', 'es_EC', {
  ...standardLocale,
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  currency: {
    symbol: '$'
  }
})

numeral.register('locale', 'es_CR', {
  ...standardLocale,

  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  currency: {
    symbol: '₡'
  }
})

numeral.register('locale', 'es_AR', {
  ...standardLocale,

  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  currency: {
    symbol: '$'
  }
})
