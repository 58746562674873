import {Context} from 'App/Root/GlobalState'
import {useContext} from 'react'
import logger from 'App/helpers/logger'

const getSavedValue = function(key, defaultValue) {
  try {
    const savedValue = localStorage.getItem(key)
    if (savedValue === null) {
      return defaultValue
    } else {
      try {
        return JSON.parse(savedValue)
      } catch (error) {
        logger.error('[getSavedValue] : Error parsing saved value', {
          key,
          savedValue,
          error
        })
        return defaultValue
      }
    }
  } catch (error) {
    logger.error('[getSavedValue] : Error getting item from localStorage', {
      key,
      error
    })
    return defaultValue
  }
}

export default function useLocalStorageState(key, defaultValue) {
  const value = getSavedValue(key, defaultValue)
  const forceUpdate = useContext(Context)

  const setValue = newValue => {
    localStorage.setItem(key, JSON.stringify(newValue))
    forceUpdate()
  }

  return [value, setValue]
}
