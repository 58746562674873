import React from 'react'
import {I18nextProvider} from 'react-i18next'
import i18n, {supportedLngs} from './i18n'
import useWebsiteInfo from 'App/hooks/useWebsiteInfo'

export default function I18NProvider(props) {
  const {websiteLocale} = useWebsiteInfo()

  if (websiteLocale) {
    const inferredLng = websiteLocale.replace('_', '-')
    const lng = supportedLngs.find(name => name === inferredLng)

    if (i18n.language !== lng) i18n.changeLanguage(lng)
  }

  return <I18nextProvider i18n={i18n}>{props.children}</I18nextProvider>
}
