export default function getPosHeaders() {
  try {
    const {remote} = window.require('electron')
    return {
      'X-ORION-POSVERSION': remote.app.getVersion()
    }
  } catch (error) {
    return {}
  }
}
