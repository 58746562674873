import React from 'react'
import styles from './styles.css'
import Container from '@justodev/parts/lib/components/Container'
import BaseStyles from 'App/Pages/Layout/BaseStyles'
import getEnv from 'App/Root/getEnv'
import PermissionsError from './PermissionsError'
import getUserFriendlyErrorInfo from 'App/helpers/misc/getUserFriendlyErrorInfo'

import logger from 'App/helpers/logger'

let reported = false

export default class ErrorHandler extends React.Component {
  state = {}

  static getDerivedStateFromError(error) {
    return {error}
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location !== this.props.location && this.state.error) {
      this.setState({error: null})
    }
  }

  componentDidCatch(error, info) {
    if (!error.message) return

    if (error.message.includes('chunk')) {
      window.location.reload(true)
      return
    }

    if (error.message.includes("User doesn't has permissions")) {
      return
    }
    logger.exception(error, info)
    if (reported) return
    reported = true

    if (error.options && error.options.query) {
      if (error.options.query.loc && error.options.query.loc.source) {
        error.options.query = error.options.query.loc.source.body
      }
    }
    if (getEnv() === 'prod') {
      setTimeout(() => {
        window.location.reload(true)
      }, 10000)
    }
  }

  renderError() {
    let message = this.state.error.message
    if (!message) return null

    if (message.includes("User doesn't has permissions")) {
      return <PermissionsError error={this.state.error} />
    }

    if (message.includes('chunk')) {
      return (
        <div className={styles.container}>
          <BaseStyles />
          <div className={styles.title}>
            <Container>Actualizando versión del sitio</Container>
          </div>
          <div className={styles.message}>
            <Container>
              <div>Estamos actualizando la versión de este sitio web</div>
            </Container>
            <br />
            <Container>Cargando...</Container>
          </div>
        </div>
      )
    }

    const {errorInfo, message: friendlyMessage} = getUserFriendlyErrorInfo(this.state.error)

    return (
      <div className={styles.container}>
        <BaseStyles />
        <div className={styles.title}>
          <Container>🙈 Ocurrió un error</Container>
          <i> ¡ Lo sentimos !</i>
        </div>
        <div className={styles.message}>
          <div>{friendlyMessage}</div>
          {errorInfo.map((info, index) => (
            <pre key={index}>{info}</pre>
          ))}
          <br />
        </div>
        <div className={styles.retry}>
          <Container>Intentando nuevamente...</Container>
        </div>
      </div>
    )
  }

  render() {
    if (this.state.error) return this.renderError()
    return this.props.children
  }
}
