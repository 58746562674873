import React from 'react'
import Root from 'App/Root'
import Pages from './Pages'
import {BrowserRouter} from 'react-router-dom'
import withSentry from 'App/helpers/sentry'

function App() {
  return (
    <BrowserRouter key={Math.random()}>
      <Root>
        <Pages />
      </Root>
    </BrowserRouter>
  )
}

export default withSentry(App)
