import React from 'react'
import OrionsoftProvider from '@justodev/parts/lib/components/Provider'
import './locale'
import PropTypes from 'prop-types'
import TwoFactorPromptProvider from './TwoFactorPromptProvider'
import './versionHelper'
import ApolloErrorHandler from 'App/components/ApolloErrorHandler'
import Session from './Session'
import {GlobalState} from './GlobalState'
// import Sentry from './Sentry'
import url from './url'
import I18NProvider from './I18NProvider'
import SuspenseLoading from 'App/components/SuspenseLoading'
import ApolloServicesClients from './apollo/ApolloServicesClients'
import ViteConnection from './ViteConnection'

export default class Root extends React.Component {
  static propTypes = {
    children: PropTypes.node
  }

  render() {
    return (
      <GlobalState>
        <ApolloServicesClients>
          <ApolloErrorHandler>
            <Session>
              <link rel="preconnect" href={url} />
              {/* <Suspense fallback={null}>
                  <Sentry />
                </Suspense> */}
              <ViteConnection>
                <OrionsoftProvider meProvider={false}>
                  <SuspenseLoading>
                    <I18NProvider>
                      <TwoFactorPromptProvider>{this.props.children}</TwoFactorPromptProvider>
                    </I18NProvider>
                  </SuspenseLoading>
                </OrionsoftProvider>
              </ViteConnection>
            </Session>
          </ApolloErrorHandler>
        </ApolloServicesClients>
      </GlobalState>
    )
  }
}
