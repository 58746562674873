import fireEvent from 'react-app-events/lib/fireEvent'
import clients from './clients'
import keys from 'lodash/keys'

export default async function(session) {
  const promises = keys(clients).map(async clientName => {
    try {
      const client = clients[clientName]

      await client.resetStore()
      await client.reFetchObservableQueries()

      if (client._wsClient) {
        client._wsClient.tryReconnect()
      }
    } catch (error) {
      console.log(`Error refetching apollo queries for client ${clientName}:`, error)
    }
  })

  await Promise.all(promises)

  fireEvent('saveSession', session || {})
}
