import React from 'react'
import styles from './styles.css'
import whatsapp from './whatsapp-logo.svg'
import useSessionStorageState from 'App/hooks/useSessionStorageState'

export default function WhatsappChat(props) {
  const {integrations: config} = props

  const [onTable] = useSessionStorageState('onTable')
  if (onTable) return null

  if (!config) return null
  if (!config.integrations) return null
  if (!config.integrations.supportWhatsappNumber) return null

  const number = config.integrations.supportWhatsappNumber.replace('+', '')

  return (
    <div className={styles.container} onClick={() => window.open(`https://wa.me/${number}`)}>
      <img src={whatsapp} />
    </div>
  )
}
