import {useEffect} from 'react'
import isScrapping from 'App/helpers/misc/isScrapping'
import {H} from 'highlight.run'
import getEnv from 'App/Root/getEnv'
import useCookiesAccepted from 'App/hooks/useCookiesAccepted'

export default function HighlightIntegration(props) {
  const {integrations: config} = props
  const cookies = useCookiesAccepted()

  useEffect(() => {
    if (
      !isScrapping &&
      config &&
      config.integrations &&
      config.integrations.highlightId &&
      cookies
    ) {
      H.init(config.integrations.highlightId, {environment: getEnv()})
    }
  }, [config, cookies])

  return null
}
