import getEnv from './getEnv'
import getCustom from './getCustom'

const urls = {
  local: `http://localhost:4112`,
  dev: 'https://auth.service.bejusto.com',
  prod: 'https://auth.service.getjusto.com'
}

const env = getEnv()

export default function getAuthURL() {
  return getCustom('auth') || urls[env]
}
