import React from 'react'
import PageMetaContext from 'App/helpers/websites/PageMetaContext'
import useState from 'App/hooks/useState'

export default function LayoutPageMeta(props) {
  const [subtitle, setSubtitle] = useState()
  const [description, setDescription] = useState()
  const [image, setImage] = useState()
  const [schema, setSchema] = useState()
  return (
    <PageMetaContext.Provider
      value={{
        subtitle,
        description,
        image,
        setSubtitle,
        setDescription,
        setImage,
        schema,
        setSchema
      }}>
      {props.children}
    </PageMetaContext.Provider>
  )
}
